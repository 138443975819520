import React from 'react';

const WalletNotification = props => {
  const { amount } = props;


  return (
    <div className="reward-cart-wrapper reward-cart-wrapper-next reward-success-state">
      <div className="reward-cart-widget">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex d-flex align-items-center">
            <i className="icon-gift-outline white-color" />
            <p className="mb-0 ml-2">
              <>{`Hurray! $${amount} credit unlocked!!`}</>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WalletNotification;
