import React, { useContext, useEffect, useState } from 'react';

import { useWalletWithPurchaseRewards } from '../../hooks/cart';

import appContext from '../../Context/appContext';

import NextRewardNotification from './NextRewardNotification';
import WalletNotification from './WalletNotification';

const RewardNotificationWrapper = () => {

  const {
    data: {
      wallet_rewards: walletRewards = {},
      next_reward: { required_subtotal: requiredSubtotal = 0, amount = 0 } = {},
      current_subtotal: currentSubtotal = 0
    } = {}
  } = useWalletWithPurchaseRewards();

  const [isWalletCreditShown, setIsWalletCreditShown] = useState(false);

  // persisits NextRewardNotification component to fill up
  // and prevent it from immediately disappearing when reward is availed
  const [isFilledRewardNotifcation, setIsFilledRewardNotifcation] = useState(false);

  const currentAvailedWalletReward = walletRewards?.amount || 0;
  const currentUnlockedTier = walletRewards?.tier_unlock_subtotal_amount || 0;

  // to handle reward message while reward meter fills up
  const isUnlockingCredit =
    isFilledRewardNotifcation && currentSubtotal && currentUnlockedTier && currentSubtotal >= currentUnlockedTier;

  useEffect(() => {
    let startTimeoutId = null;
    let endTimeoutId = null;

    if (currentAvailedWalletReward) {
      setIsFilledRewardNotifcation(true);

      // delays WalletNotification component till
      // NextRewardNotification completely fills up
      startTimeoutId = setTimeout(() => {
        setIsFilledRewardNotifcation(false);
        setIsWalletCreditShown(true);

        clearTimeout(startTimeoutId);
      }, 900);

      // clear wallet notification as it will
      // be shown as a total under cart rewards section
      endTimeoutId = setTimeout(() => {
        setIsWalletCreditShown(false);

        clearTimeout(endTimeoutId);
      }, 3000);
    }

    return () => {
      if (startTimeoutId || endTimeoutId) {
        clearTimeout(startTimeoutId);
        clearTimeout(endTimeoutId);
      }
    };
  }, [currentAvailedWalletReward]);

  if (isWalletCreditShown && currentAvailedWalletReward) {
    return <WalletNotification amount={currentAvailedWalletReward} isWalletCreditShown={isWalletCreditShown} />;
  }

  if (
    (!isWalletCreditShown && currentSubtotal && requiredSubtotal) ||
    (isFilledRewardNotifcation && currentSubtotal && currentUnlockedTier && currentSubtotal >= currentUnlockedTier)
  ) {
    return (
      <NextRewardNotification
        currentSubtotal={currentSubtotal}
        requiredSubtotal={requiredSubtotal}
        rewardAmount={amount}
        isUnlockingCredit={isUnlockingCredit}
      />
    );
  }

  return null;
};

export default RewardNotificationWrapper;
