import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import appContext from '../Context/appContext';

const WalletWithPurchaseRewardSection = () => {
  // const {
  //   state: {
  //     cart: { cartData: { walletWithPurchaseAmount } = {} }
  //   }
  // } = useContext(appContext);

  const { walletWithPurchaseAmount } = useSelector(state => state.cart?.cartData) || {}

  if (walletWithPurchaseAmount) {
    return (
      <>
        <div className="added-rewards-wrapper">
          <div className="added-rewards-widget relative">
            <div className="added-rewards d-none">
              <p className="mb-0 white-color align-self-end">REWARDS</p>
            </div>

            <div className="d-flex wallet-with-purchase-wrapper align-items-center">
              <div className="wallet-with-purchase-amt bold">{`$${walletWithPurchaseAmount}`}</div>

              <div className="wallet-with-purchase-amt-text">
                <p className="bold mb-0">Credit Unlocked!</p>

                <p className="mb-0 line-height-1">Will be added to your wallet post delivery.</p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return null;
};

export default WalletWithPurchaseRewardSection;
